import React, { useState, useEffect, useContext } from 'react'
import Popup from 'reactjs-popup';
import { APIRequestContext } from '../wrappers/APIRequestContext'
import withConfig from '../wrappers/withConfig'
import { useNavigate } from "react-router-dom";
import urls from '../../utils/constants/urls';
import toast from './Toast'

const HomePage = ({ config }) => {
    const [showAbout, setShowAbout] = useState(false)
    const [ states, setStates ] = useState([])
    const [ authenticatedFetch, env ] = useContext(APIRequestContext)
    const { API_URL } = config

    const showAboutBox = () => {
        setShowAbout(true)
    }

    const hideAboutBox = () => {
        setShowAbout(false)
    }

    useEffect(() => {
        loadStates()
    }, [env]);

    const loadStates = () => {
        authenticatedFetch(`${API_URL}?method=getStates2`, {
            crossDomain: true })
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                if (response != null && response.ADATA.length > 0)
                    setStates(response.ADATA)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(

            )
    }

    return (
        <div style={{ 
          backgroundImage: "url(/wf-background.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto 100%",
          backgroundPosition: "center",
          backgroundColor: "#023a51",
          height: "600px",
          position: "relative"
        }}>
            <div className="wf-buttons">
            <a className="wf-button" href="https://www.rbdms.org/rbdms-products/well-finder/">
                <img className="wf-img" src="/images/oil-well.png" />Download Mobile Application
            </a>
            <a className="wf-button" onClick={showAboutBox}>
                <img className="wf-img" src="/images/question-circle-96dp.png" />About RBDMS WellFinder
            </a>
            </div>
            <div className="wf-bottom"><img src="wf-bottom.png" /></div> 
            {showAbout && 
            <Popup className="about" open={true} closeOnDocumentClick onClose={hideAboutBox}>
                <>
                <h1>About RBDMS&copy; WellFinder</h1>
                <hr/>
                <p>
                RBDMS WellFinder allows users to locate and select Oil &amp; Gas wells managed by State Oil &amp; Gas Regulatory Agencies and display select well level data. Data includes API (permit) numbers, location, well type (oil, gas, injection, etc.), well status (active, plugged), operator information, and links to additional data available at the State Agencies.
                </p>
                <p>
                RBDMS WellFinder is a product of the award-winning Ground Water Protection Council’s Risk Based Data Management System, which provides powerful analytical tools for state regulatory programs and the public. RBDMS is partially funded by a grant from US DOE.
                </p>
                <p>For more information on RBDMS please visit: <a target="_blank" href="https://www.rbdms.org/">https://www.rbdms.org/</a></p>
                <p>For more information on WellFinder please visit: <a target="_blank" href="https://www.rbdms.org/rbdms-products/well-finder/">https://www.rbdms.org/rbdms-products/well-finder/</a></p>
                <p>For more information on the Ground Water Protection Council please visit: <a target="_blank" href="https://www.gwpc.org/">https://www.gwpc.org/</a></p>
                <hr/>
                { states.length > 0 &&
                <div className="wf-states">
                    <h1>List of Participating States</h1>
                    <table>
                        <thead>
                        <tr>
                            <th>State</th>
                            <th>Agency Name</th>
                            <th>Date Added</th>
                        </tr>
                        </thead>
                        <tbody>
                        {states.sort((a, b) => a.NAME > b.NAME ? 1 : -1)
                            .map((item, i) => (
                                <tr key={i}>
                                    <td>{item.NAME}</td>
                                    <td>{item.AGENCYNAME}</td>
                                    <td>{item.WFSTARTDATE}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                </div>}
                <hr />
                <a className="button" onClick={hideAboutBox}>
                    <strong>Close</strong>
                </a>
                </>
            </Popup>}           
        </div>
      );
}

export default withConfig(HomePage)