import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  FooterText
} from "./FooterStyles";
import NavLink from './NavLink'
import DevSwitch from './DevSwitch'
import urls from '../../utils/constants/urls'
  
const Footer = () => {
  return (
    <Box>
      <Container>
        <Row>
          <Column>
            <Heading>Navigation</Heading>
            <NavLink name="Home" route={urls.home} />
            <NavLink name="Launch Map" route={urls.map} icon="/navbar-icons/nearby-wells-96dp.png" />
            <NavLink name="Search by API" route={urls.apiSearch} icon="/navbar-icons/search.png" />
            <NavLink name="Search by Well Name" route={urls.wellNameSearch} icon="/navbar-icons/search.png" />
            <NavLink name="Browse Wells" route={urls.browseWells} icon="/navbar-icons/list-ul.png" />
            <NavLink name="Saved Wells" route={urls.savedWells} icon="/navbar-icons/star-96dp.png" />
          </Column>
          <Column>
            <Heading>Links</Heading>
            <FooterLink href="https://www.rbdms.org/rbdms-products/well-finder/">Users Guide and Information</FooterLink>
            <FooterLink href="https://www.rbdms.org/rbdms-products/well-finder/">About RBDMS WellFinder</FooterLink>
            <FooterLink href="https://www.gwpc.org/overview/">About GWPC</FooterLink>
            <FooterLink href="https://itunes.apple.com/us/app/rbdms-wellfinder/id972680525?mt=8">Download RBDMS WellFinder (iOS)</FooterLink>
            <FooterLink href="https://play.google.com/store/apps/details?id=com.troyweb.gwpc">Download RBDMS WellFinder (Android)</FooterLink>
          </Column>
          <Column>
            <Heading>Contact</Heading>
            <FooterText>Ground Water Protection Council, Inc.</FooterText>
            <FooterText>13308 N MacArthur Blvd</FooterText>
            <FooterText>Oklahoma City, OK 73142</FooterText>
            <br/>
            <FooterLink href="mailto:wellfinder@gwpc.org">wellfinder@gwpc.org</FooterLink>
            <br/>
            <DevSwitch />          
          </Column>
        </Row>
      </Container>
    </Box>
  );
};
export default Footer;