import React, {useContext, useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import urls from '../../utils/constants/urls';
import { SavedWellsContext } from '../wrappers/SavedWellsContext'

const WellDetails = ({ result, onClose, showZoom }) => {
    const navigate = useNavigate();
    const [ wells, addWell, removeWell ] = useContext(SavedWellsContext)
    const [saved, setSaved] = useState();

    useEffect(() => {
        if (wells != null && wells.ADATA.length > 0) {
            setSaved(wells.ADATA.findIndex(e => e.API == result.API && e.GEOMETRY[0] == result.GEOMETRY[0] && e.GEOMETRY[1] == result.GEOMETRY[1]) !== -1)
        }
        else {
            setSaved(false)
        }
    }, [wells])
    
    if (!result || result.API === undefined) {
        return null
    }

    const zoomToWell = () => {
        let lat = result.GEOMETRY[1].toFixed(4)
        let lng = result.GEOMETRY[0].toFixed(4)

        window.sessionStorage.setItem('zoom', 17)
        window.sessionStorage.setItem('latitude', lat)
        window.sessionStorage.setItem('longitude', lng)

        navigate(urls.map)
    }

    const save = () => {
        addWell(result)
    }

    const remove = () => {
        removeWell(result)
    }

    return (
        <div className="well-details">
            <table className="details-header">
                <tbody>
                    <tr>
                        <td>{result.NAME}</td>
                        {saved? <td><img src="/images/star-96dp.png" onClick={remove} /><span className="details-save">Saved</span></td> :
                        <td><img src="/images/star-outline-96dp.png" onClick={save} /><span className="details-save">Save</span></td>}          
                    </tr>
                </tbody>
            </table>
            <p className="details-api">API: {result.API}</p>
            <p>Company: {result.COMPANY}</p>
            <p>Well Type: {result.TYPE}</p>
            <p>Well Status: {result.STATUS}</p>
            <p>State: {result.STATE}</p>
            <p>County: {result.COUNTY}</p>
            <p>Section: {result.SECTION}</p>
            <p>Township: {result.TWP}</p>
            <p>Range: {result.RNG}</p>
            <p>Field: {result.FIELD}</p>
            <p>Latitude: {result.GEOMETRY[1]}</p>
            <p>Longitude: {result.GEOMETRY[0]}</p>
            { showZoom && <a className="button" onClick={zoomToWell}>
                <strong>Zoom to Map</strong>
            </a>}
            { result.WELL_LINK && <a className="button" target="_blank" href={result.WELL_LINK}>
                <strong>More Details</strong>
            </a>}
            { result.FRACFOCUSURL && <a className="button" target="_blank" href={result.FRACFOCUSURL}>
                <strong>FracFocus Details</strong>
            </a>}
            { onClose !== undefined &&
            <a className="button" onClick={onClose}>
                <strong>Close</strong>
            </a>}
        </div>
    )
}

export default WellDetails