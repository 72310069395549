import React, { useState } from 'react'

const SortByPanel = ( {names, selected, order, sortByChanged} ) => {
    const [ sortBy, setSortBy ] = useState(selected)
    const [ sortUp, setSortUp ] = useState(order)

    const valueChanged = (item) => {
        sortByChanged(item, sortUp)
        setSortBy(item)
    }

    const toggleSortOrder = () => {
        sortByChanged(sortBy, !sortUp)
        setSortUp(!sortUp)
    }

    return (
        <div className="sort-by">
            <span className="sort-buttons">
                <span className="sort-by-text">Sort by:</span>          
                {names.map((item, i) => (
                    <a key={i} className={item === sortBy? "button is-link is-rounded" : "button is-rounded sort-by-unsel"} onClick={() => valueChanged(item)}>{item}</a>
                ))}
            </span>
            <span className="sort-order">
                <a className="button is-link is-rounded " onClick={toggleSortOrder}><img src={sortUp? "/images/sort-alpha-up.png" : "/images/sort-alpha-down.png"} /></a>
            </span>         
        </div>
    )
}

export default SortByPanel