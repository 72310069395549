import './App.css';
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/elements/Header';
import HomePage from './components/elements/HomePage';
import MapPage from './components/elements/MapPage';
import SearchWellsPage from './components/elements/SearchWellsPage';
import BrowseWellsPage from './components/elements/BrowseWellsPage';
import SavedWellsPage from './components/elements/SavedWellsPage';
import NoPage from './components/elements/NoPage';
import NavBar from './components/elements/NavBar';
import Footer from './components/elements/Footer';
import urls from './utils/constants/urls'
import APIRequestContextProvider from './components/wrappers/APIRequestContext'
import FilterContextProvider from './components/wrappers/FilterContext';
import SavedWellsContextProvider from './components/wrappers/SavedWellsContext';

const App = () => (
  <React.Fragment>
    <APIRequestContextProvider>
      <FilterContextProvider>
        <SavedWellsContextProvider>
          <Router>
            <Header />
            <NavBar />
            <div className="pageContent">                    
                <Routes>           
                    <Route exact path={urls.home} element={<HomePage />} />
                    <Route exact path={urls.map} element={<MapPage />} />
                    <Route exact path={urls.apiSearch} element={<SearchWellsPage searchBy="api" />} />
                    <Route exact path={urls.apiSearch + "/:api"} element={<SearchWellsPage searchBy="api" />} />
                    <Route exact path={urls.idSearch + "/:id"} element={<SearchWellsPage searchBy="api" />} />
                    <Route exact path={urls.wellNameSearch} element={<SearchWellsPage searchBy="name" />} />
                    <Route exact path={urls.browseWells} element={<BrowseWellsPage />} />
                    <Route exact path={urls.savedWells} element={<SavedWellsPage />} />
                    <Route path="*" element={<NoPage />} />
                </Routes>      
            </div>
            <Footer />
          </Router>
        </SavedWellsContextProvider>
      </FilterContextProvider>
    </APIRequestContextProvider>
  </React.Fragment>
)

export default App;
