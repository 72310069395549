import React, { useContext, useState } from 'react'
import Switch from './Switch'
import { FilterContext } from '../wrappers/FilterContext'
import Popup from 'reactjs-popup';

const LayerFilter = ({ showPasswordDialog }) => {
    const [ filter, setFilter ] = useContext(FilterContext)
    const [showInfo, setShowInfo] = useState(false);

    const setFilterOil = (val) => {
        let f = { ...filter }
        f.Oil = val
        setFilter(f);
    }

    const setFilterGas = (val) => {
        let f = { ...filter }
        f.Gas = val
        setFilter(f);
    }

    const setFilterOG = (val) => {
        let f = { ...filter }
        f.OG = val
        setFilter(f);
    }

    const setFilterSWD = (val) => {
        let f = { ...filter }
        f.SWD = val
        setFilter(f);
    }

    const setFilterEOR = (val) => {
        let f = { ...filter }
        f.EOR = val
        setFilter(f);
    }

    const setFilterOTHER = (val) => {
        let f = { ...filter }
        f.OTHER = val
        setFilter(f);
    }

    const setFilterIsVisible = (val) => {
        let f = { ...filter }
        f.IsVisible = val
        setFilter(f);
    }

    const showInformation = () => {
        setShowInfo(true)
    }

    const hideInformation = (event) => {
        event.preventDefault()
        setShowInfo(false)
    }

    return (
        <>
            <table className="layers-info">
                <tbody>
                    <tr>
                        <td className="layers-info-img"><img src="/images/info.png" onClick={showInformation} /></td>
                        <td>Well Filters</td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <p onClick={() => setFilterIsVisible(false)}>
                Show All Wells {!filter.IsVisible && <img className="layers-check" src="/images/check.png" />}
            </p>
            <hr />
            <p onClick={() => setFilterIsVisible(true)}>
                Show Visible Wells Only {filter.IsVisible && <img className="layers-check" src="/images/check.png" />}
            </p>
            <hr />
            <p>
                Well Filter Type
            </p>
            <table>
                <tbody>
                    <tr>
                        <td><img src="/images/types/oil.png" /></td>
                        <td>Oil</td>
                        <td><Switch id="layer-switch-1" check={filter.Oil} onChanged={setFilterOil} /></td>
                    </tr>
                    <tr>
                        <td><img src="/images/types/gas.png" /></td>
                        <td>Gas</td>
                        <td><Switch id="layer-switch-2" check={filter.Gas} onChanged={setFilterGas} /></td>
                    </tr>
                    <tr>
                        <td><img src="/images/types/oilgas.png" /></td>
                        <td>Oil &amp; Gas</td>
                        <td><Switch id="layer-switch-3" check={filter.OG} onChanged={setFilterOG} /></td>
                    </tr>
                    <tr>
                        <td><img src="/images/types/swd.png" /></td>
                        <td>Salt Water Disposal</td>
                        <td><Switch id="layer-switch-4" check={filter.SWD} onChanged={setFilterSWD} /></td>
                    </tr>
                    <tr>
                        <td><img src="/images/types/eor.png" /></td>
                        <td>Enhanced Oil Recovery</td>
                        <td><Switch id="layer-switch-5" check={filter.EOR} onChanged={setFilterEOR} /></td>
                    </tr>
                    <tr>
                        <td><img src="/images/types/other.png" /></td>
                        <td>Other</td>
                        <td><Switch id="layer-switch-6" check={filter.OTHER} onChanged={setFilterOTHER} /></td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <div className="version-info" onClick={showPasswordDialog}>
                Version 1.0.0
            </div>
            {showInfo && 
            <Popup className="layerfilter" open={true} closeOnDocumentClick onClose={hideInformation}>
                <>
                <h1>Information</h1>
                <p>
                This filter controls which wells are displayed based on the visibility of a well from the surface. You can view "All Wells" or "Visible Wells Only" with this filter.</p>
                <hr/>
                <a onClick={hideInformation}>
                    <strong>OK</strong>
                </a>
                </>
            </Popup>}
        </>
    )
}

export default LayerFilter