import React, { useState, useEffect, useContext } from 'react'
import { useParams  } from 'react-router'
import { APIRequestContext } from '../wrappers/APIRequestContext'
import withConfig from '../wrappers/withConfig'
import toast from './Toast'
import WellList from './WellList'
import WellDetails from './WellDetails'

const SearchWellsPage = ({ config, searchBy }) => {

    const [ search, setSearch ] = useState('')
    const [ result, setResult ] = useState([])
    const [ well, setWell ] = useState({})
    const [ authenticatedFetch, env ] = useContext(APIRequestContext)
    const { API_URL } = config
    const { api, id } = useParams()

    const handleChange = (e) => {
        setSearch(e.target.value)
        window.sessionStorage.setItem(`${searchBy}Search`, e.target.value)
    }

    useEffect(() => {
        // set initial value
        if (id !== undefined) {
            doSearch()
        }
        else if (api !== undefined) {
            setSearch(api)
            doSearch()
        }
        else {
            let val = window.sessionStorage.getItem(`${searchBy}Search`)
            if (val != null)
                setSearch(val)

            setResult([])
        }     
    }, [env]);

    const wellSelected = (item) => {
        setWell(item)
    }

    const onCloseDetails = () => {
        setWell({})
    }

    const doSearch = () => {
        setResult('loading')
        let url = (searchBy === 'api'? `${API_URL}?method=getWellByAPI&api=${search}` : `${API_URL}?method=getWellByName&name=${search}`)
        if (id !== undefined) {
            url = `${API_URL}?method=getWellByID&id=${id}`
        }
        else if (api !== undefined) {
            url = `${API_URL}?method=getWellByAPI&api=${api}`
        }

        authenticatedFetch(url, {
            crossDomain: true })
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setResult(response)
                if ((id !== undefined || api !== undefined) && response.ADATA.length == 1) {
                    setWell(response.ADATA[0]);
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(
                
            )
    }

    if (well.API !== undefined) {
        return (
            <WellDetails result={well} onClose={onCloseDetails} showZoom={true} />
        )
    }

    return (
        <div className="search">
            { api === undefined && <><img src="/search.png"></img>
            <input 
                type="text" 
                name="name" 
                placeholder={searchBy === "api"? "Enter API Number" : "Enter Well Name"}
                value = {search}
                onChange={handleChange}
            />
            
            <a className="button" onClick={doSearch}>
                <strong>Search</strong>
            </a></>}
            
            <WellList result={result} onSelected={wellSelected} />
        </div>       
    )
}

export default withConfig(SearchWellsPage)