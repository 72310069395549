import React, { createContext, useCallback, useState } from 'react'

const APIRequestContext = createContext(null)

const APIRequestContextProvider = ({ children }) => {
    const apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL3dlbGxmaW5kZXIucmJkbXMub3JnIiwiYXVkIjoiaHR0cHM6Ly93ZWxsZmluZGVyLnJiZG1zLm9yZyIsInNjb3BlIjoiV2VsbEZpbmRlclRlc3RBcHAiLCJqdGkiOiIxMUJFOEU0My04RDEzLTQ3MTYtOERGQS1BNDFERUFBREEwNTEiLCJpYXQiOjE0OTI3MTk3NTYuNDc5NTA3Mn0.2GxoPpGhd26T4v2VoROSZ_A-d7hi2Coc_KeyQrbc3-U'
    const [env, setEnv] = useState(window.localStorage.getItem("environment") === "development"? "development" : "production")

    const setEnvironment = (e) => {
        setEnv(e)
        window.localStorage.setItem("environment", e)
    }

    const authenticatedFetch = useCallback(async (endpoint, options) => {
        let fetchOptions = {}
        if (options) {
            fetchOptions = {
                ...options,
                headers: {
                    ...options.headers,
                    Authorization: `Bearer ${apiKey}`,
                    Environment: `${env}`
                },
            }
        } else {
            fetchOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                    Environment: `${env}`
                },
            }
        }
        return fetch(endpoint, fetchOptions)
    }, [apiKey, env])

    return (
        <APIRequestContext.Provider
            value={[authenticatedFetch, env, setEnvironment]}
        >
            {children}
        </APIRequestContext.Provider>
    )
}

export { APIRequestContext }
export default APIRequestContextProvider
