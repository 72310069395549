import React from 'react'

const NoPage = () => {
    return (
        <div className="nopage">
            <h1>Page Not Found</h1>
            <a href="/">Back To Home</a>
        </div>
    )
}

export default NoPage