import React, { useContext } from 'react'
import {
    FooterText
  } from "./FooterStyles"
import Switch from './Switch'
import { APIRequestContext } from '../wrappers/APIRequestContext'
import { FilterContext } from '../wrappers/FilterContext'

const DevSwitch = () => {

    const [ , env, setEnvironment ] = useContext(APIRequestContext)
    const [ , , unlocked ] = useContext(FilterContext)

    const setDevMode = (value) => {
       if (value)
         setEnvironment("development")
       else
         setEnvironment("production")
    }
    
    return (
        <>
            {unlocked &&
            <table className="dev-switch">
              <tbody>
                <tr>
                  <td><FooterText>Development Mode</FooterText></td>
                  <td><Switch id="dev-switch" check={env === "development"} onChanged={setDevMode} /></td>
                </tr>
              </tbody>
            </table>}
        </>
    )
}

export default DevSwitch