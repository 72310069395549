import React, { createContext, useState } from 'react'

const FilterContext = createContext(null)

const FilterContextProvider = ({ children }) => {

    const getFilter = () => { 
        let fs = window.sessionStorage.getItem('filter')
        if (fs != null) {
            let f = JSON.parse(fs)
            if (f != null && f.Oil !== undefined) {
                return f
            }
        }
        
        return {
            Oil: true,
            Gas: true,
            OG: true,
            EOR: true,
            SWD: true,
            OTHER: true,
            IsVisible: false
        }
    }

    const getUnlocked = () => { 
        let val = window.sessionStorage.getItem('unlocked')
        if (val != null) {
            return val
        }
        
        return false
    }

    const [filter, setFilter2] = useState(getFilter())
    const [unlocked, setUnlocked2] = useState(getUnlocked())

    const setFilter = (val) => { 
        window.sessionStorage.setItem('filter', JSON.stringify(val))
        setFilter2(val)
    }

    const setUnlocked = (val) => { 
        window.sessionStorage.setItem('unlocked', val)
        setUnlocked2(val)
    }

    return (
        <FilterContext.Provider
            value={[filter, setFilter, unlocked, setUnlocked]}
        >
            {children}
        </FilterContext.Provider>
    )
}

export { FilterContext }
export default FilterContextProvider
