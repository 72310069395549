import React, { useState, useContext, useEffect } from 'react'
import { APIRequestContext } from '../wrappers/APIRequestContext'
import withConfig from '../wrappers/withConfig'
import Select from 'react-select'
import toast from './Toast'

const MapZoom = ({ config, zoomToBounds }) => {
    const [ states, setStates ] = useState([])
    const [ state, setState ] = useState(null)
    const [ counties, setCounties ] = useState([])
    const [ loading, setLoading ] = useState(null)
    const [ county, setCounty ] = useState(null)
    const [ towns, setTowns ] = useState([])
    const [ town, setTown ] = useState([])
    const [ authenticatedFetch, env ] = useContext(APIRequestContext)
    const { API_URL } = config
    
    useEffect(() => {
        loadStates()
        setState(null)
        setCounty(null)
        setTown(null)
    }, [env]);

    useEffect(() => {
        setCounties([]);
        setCounty(null);
        setTowns([]);
        setTown(null);
        if (state != null)
            loadCounties()
    }, [state]);

    useEffect(() => {
        setTowns([]);
        setTown(null);
        if (county != null)
            loadTowns()
    }, [county]);

    const showObject = (object) => {
        // set bounds in [sw, ne]
        let bounds = [
            [object.CENTER[0] - object.LONGDELTA / 2, object.CENTER[1] - object.LATDELTA / 2],
            [object.CENTER[0] + object.LONGDELTA / 2, object.CENTER[1] + object.LATDELTA / 2]]
        
        zoomToBounds(bounds) 
    }

    const zoomBounds = () => {
        if (town != null)
            showObject(town)
        else if (county != null)
            showObject(county)
        else if (state != null)
            showObject(state)
    }

    const loadStates = () => {
        setLoading('states')
        authenticatedFetch(`${API_URL}?method=getStates2`, {
            crossDomain: true })
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setLoading(null)
                if (response != null && response.ADATA.length > 0)
                    setStates(response.ADATA.sort((a, b) => a.NAME > b.NAME ? 1 : -1))
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(

            )
    }

    const loadCounties = () => {
        setLoading('counties')
        authenticatedFetch(`${API_URL}?method=getCountiesByState&state=${state.ABBR}`, {
            crossDomain: true })
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setLoading(null)
                if (response != null && response.ADATA.length > 0)
                    setCounties(response.ADATA.sort((a, b) => a.NAME > b.NAME ? 1 : -1))
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(

            )
    }

    const loadTowns = () => {
        setLoading('towns')
        authenticatedFetch(`${API_URL}?method=getTownsByCounty&state=${state.ABBR}&county=${county.NAME}`, {
            crossDomain: true })
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setLoading(null)
                if (response != null && response.ADATA.length > 0)
                    setTowns(response.ADATA.sort((a, b) => a.NAME > b.NAME ? 1 : -1))
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(

            )
    }

    return (
        <>
            <h2>
                State
            </h2>
            <Select 
                value={state} 
                options={states} 
                getOptionValue={(state) => state.ABBR} 
                getOptionLabel={(state) => state.NAME} 
                placeholder={loading == 'states' ? "Loading States..." : "Select a State"}
                onChange={setState}
            />
            <h2>
               County
            </h2>
            <Select 
                value={county} 
                options={counties} 
                getOptionValue={(county) => county.NAME} 
                getOptionLabel={(county) => county.NAME} 
                placeholder={loading == 'counties' ? "Loading Counties..." : "Select a County"}
                onChange={setCounty}
            />
            <h2>
               Town / Township
            </h2>
            <Select 
                value={town} 
                options={towns} 
                getOptionValue={(town) => town.NAME} 
                getOptionLabel={(town) => town.NAME} 
                placeholder={loading == 'towns' ? "Loading Towns..." : "Select a Town"}
                onChange={setTown}
            />

            <a className="button" onClick={zoomBounds}>
                <strong>Zoom To {town != null? town.NAME : (county != null? county.NAME : (state != null? state.NAME : "..."))}</strong>
            </a>
        </>
    )
}

export default withConfig(MapZoom)