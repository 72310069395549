import React, { useState, useEffect, useContext }  from 'react'
import { APIRequestContext } from '../wrappers/APIRequestContext'
import { FilterContext } from '../wrappers/FilterContext'
import withConfig from '../wrappers/withConfig'
import DetailsLink from './DetailsLink'
import SortByPanel from './SortByPanel'
import toast from './Toast'
import ReactTooltip from "react-tooltip"
import { useNavigate } from "react-router-dom";
import urls from '../../utils/constants/urls';

const CountyList = ({ config, state, onSelected, onBack }) => {

    const [ result, setResult ] = useState([])
    const [ counties, setCounties ] = useState([])
    const [ authenticatedFetch, env ] = useContext(APIRequestContext)
    const [ filter ] = useContext(FilterContext)
    const { API_URL } = config
    const navigate = useNavigate();
    
    useEffect(() => {
        loadCounties()
    }, [env, filter]);

    useEffect(() => {
        if (result && result.ADATA instanceof Array)
            sortByChanged('Name', false);
    }, [result]);

    useEffect(() => {
        ReactTooltip.rebuild();
    });

    const loadCounties = () => {
        setResult('loading')
        authenticatedFetch(`${API_URL}?method=getCountiesByState&state=${state}&filter=${JSON.stringify(filter)}`, {
            crossDomain: true })
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setResult(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(
                
            )
    }

    const handleClick = (county) => {
        onSelected(state, county)
    }

    const back = () => {
        onBack()
    }

    const showCounty = (county) => {
        // set bounds in [sw, ne]
        let bounds = [
            [county.CENTER[0] - county.LONGDELTA / 2, county.CENTER[1] - county.LATDELTA / 2],
            [county.CENTER[0] + county.LONGDELTA / 2, county.CENTER[1] + county.LATDELTA / 2]]


        window.sessionStorage.setItem('bounds', JSON.stringify(bounds))
        window.sessionStorage.setItem('latitude', 39.8283)
        window.sessionStorage.setItem('longitude', -98.5795)
        window.sessionStorage.setItem('zoom', 3.5)

        navigate(urls.map)
    }

    if (result === 'loading') {
        return (<h2>Loading ...</h2>)
    }

    if (!result || result.SUCCESS === undefined) {
        return null
    }

    if (result.SUCCESS !== true) {
        return (
            <h2>{result.MESSAGE}</h2>
        )
    }

    const sortByChanged = (item, sortUp) => {
        if (item === 'Name') {
            if (sortUp)
                setCounties(result.ADATA.slice().sort((a, b) => a.NAME < b.NAME ? 1 : -1))
            else
                setCounties(result.ADATA.slice().sort((a, b) => a.NAME > b.NAME ? 1 : -1))
        }
        else if (item === 'Well Count') {
            if (sortUp)
                setCounties(result.ADATA.slice().sort((a, b) => Number(a.WELLCOUNT) < Number(b.WELLCOUNT) ? 1 : -1))
            else
                setCounties(result.ADATA.slice().sort((a, b) => Number(a.WELLCOUNT) > Number(b.WELLCOUNT) ? 1 : -1))
        }
    }

    return (
        <>
            <ReactTooltip id="tooltip-info" place="right" type="dark" effect="float" />
            <table>
                <tbody>
                    <tr className="search-header">
                        <td colSpan={4}><span className="nav-back"><img src="/images/angle-back.png" onClick={back} /> Back</span><span className="search-title">Counties</span></td>   
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            <SortByPanel names={['Name', 'Well Count']} selected='Name' order={false} sortByChanged={sortByChanged} />    
                        </td>
                    </tr>
                    {counties.map((item, i) => (
                        <tr key={i}>
                            <td className="search-img"><a data-for="tooltip-info" data-tip="Show on Map" onClick={() => showCounty(item)}><img src="/images/map-marker-alt.png" /></a></td>
                            <td className="search-data"><span>{item.NAME}, {item.STATE}</span></td>
                            <td className="search-wellcount"><span>{item.WELLCOUNT}</span></td>
                            <td className="nav-next"><DetailsLink value={item.NAME} handleClick={handleClick} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default withConfig(CountyList)