import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {
  FooterLink,
  FooterLinkNavigated
} from "./FooterStyles";

const NavLink = ({ name, route }) => {
    return (
        <Routes>
          <Route path={route} element={<FooterLinkNavigated href={route}>{name}</FooterLinkNavigated>} />
          <Route path="*" element={<FooterLink href={route}>{name}</FooterLink>} />
        </Routes>   
    )
}

export default NavLink
