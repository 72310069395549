import React, { useState, useEffect, useContext } from 'react'
import StateList from './StateList'
import CountyList from './CountyList'
import { APIRequestContext } from '../wrappers/APIRequestContext'
import { FilterContext } from '../wrappers/FilterContext'
import withConfig from '../wrappers/withConfig'
import toast from './Toast'
import WellList from './WellList'
import WellDetails from './WellDetails'

const BrowseWellsPage = ({ config }) => {
    const [ state, setState ] = useState('')
    const [ county, setCounty ] = useState('')
    const [ result, setResult ] = useState([])
    const [ well, setWell ] = useState({})
    const [ authenticatedFetch, env ] = useContext(APIRequestContext)
    const [ filter ] = useContext(FilterContext)
    const { API_URL } = config

    const stateSelected = (state) => {
        setState(state)
    }

    useEffect(() => {
        countySelected(state, county)
    }, [env, filter]);

    const countySelected = (s, c) => {
        setState(s)
        setCounty(c)
        setResult('loading')
        authenticatedFetch(`${API_URL}?method=getWellsByCounty&state=${s}&county=${c}&filter=${JSON.stringify(filter)}`, {
            crossDomain: true })
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setResult(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(
                
            )
    }

    const wellSelected = (item) => {
        setWell(item)
    }

    const onCloseDetails = () => {
        setWell({})
    }

    const onBack = () => {
        setWell({})
        setCounty('')
        setState('')
    }

    if (state === '') {
        return (
            <div className="search">
                <StateList onSelected={stateSelected} />
            </div>
        )
    }

    if (county === '') {
        return (
            <div className="search">
                <CountyList state={state} onSelected={countySelected} onBack={onBack} />
            </div>
        )
    }

    if (well.API !== undefined) {
        return (
            <WellDetails result={well} onClose={onCloseDetails} showZoom={true} />
        )
    }

    return (
        <div className="search">
            <WellList result={result} onSelected={wellSelected} />
        </div>      
    )
}

export default  withConfig(BrowseWellsPage)