import React, { useState, useContext } from 'react'
import withConfig from '../wrappers/withConfig'
import WellList from './WellList'
import WellDetails from './WellDetails'
import { SavedWellsContext } from '../wrappers/SavedWellsContext'

const SavedWellsPage = ({ config }) => {
    const [ well, setWell ] = useState({})
    const [ wells ] = useContext(SavedWellsContext)

    const wellSelected = (item) => {
        setWell(item)
    }

    const onCloseDetails = () => {
        setWell({})
    }

    if (well.API !== undefined) {
        return (
            <WellDetails result={well} onClose={onCloseDetails} showZoom={true} />
        )
    }

    return (
        <div className="search">
            <WellList result={wells} onSelected={wellSelected} />
        </div>      
    )
}

export default  withConfig(SavedWellsPage)