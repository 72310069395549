import React from 'react'

const DetailsLink = ({ value, handleClick }) => {
    
    const linkClicked = () => {
        handleClick(value)
    }

    return (
        <img src="/images/angle-right.png" onClick={linkClicked} />
    )
}

export default DetailsLink