import React, { useState, useContext } from 'react'
import urls from '../../utils/constants/urls'
import NavBarItem from './NavBarItem'
import Popup from 'reactjs-popup';
import LayerFilter from './LayerFilter';
import { FilterContext } from '../wrappers/FilterContext'

const NavBar = () => {
    const [active, setActive] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [ password, setPassword ] = useState('')
    const [ , , unlocked, setUnlocked ] = useContext(FilterContext)

    const showPasswordDialog = () => {
        if (!unlocked)
            setShowPassword(true)
    }
    
    
    const hidePasswordDialog = (event) => {
        event.preventDefault()
        setShowPassword(false)
    }

    const validatePassword = (event) => {
        event.preventDefault()
        if (password === '731422')
            setUnlocked(true)

        setShowPassword(false)
    }

    const handleChange = (e) => {
        setPassword(e.target.value)
    }

    return (
        <>
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                 
                <img className="navbar-logo" src="/wellfinder-logo.png" />

                <a 
                    role="button" 
                    className={`navbar-burger ${active ? 'is-active' : ''}`}
                    onClick={() => {
                        setActive(!active)
                    }}
                    aria-label="menu" 
                    aria-expanded="false" 
                    data-target="navMenu"
                >
                <span></span>
                <span></span>
                <span></span>
                </a>
            </div>

            <div id="navMenu" className={`navbar-menu ${active ? 'is-active' : ''}`}>
                <div className="navbar-start">
                    <NavBarItem name="Home" route={urls.home} icon="/navbar-icons/home.png" />
                    <NavBarItem name="Launch Map" route={urls.map} icon="/navbar-icons/nearby-wells-96dp.png" />
                    <NavBarItem name="Search by API" route={urls.apiSearch} icon="/navbar-icons/search.png" />
                    <NavBarItem name="Search by Well Name" route={urls.wellNameSearch} icon="/navbar-icons/search.png" />
                    <NavBarItem name="Browse Wells" route={urls.browseWells} icon="/navbar-icons/list-ul.png" />
                    <NavBarItem name="Saved Wells" route={urls.savedWells} icon="/navbar-icons/star-96dp.png" />
                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                    <Popup className="layers" position="bottom right" trigger={<span><img className="filter-button" src="/images/filter.png" />Filter</span>} >
                        <LayerFilter showPasswordDialog={showPasswordDialog} />
                    </Popup>
                    </div>
                    {showPassword && 
                        <Popup className="password" open={true}>
                            <div>
                                <h1>Enter Password</h1>
                                <input 
                                    type="text" 
                                    name="name" 
                                    value={password}
                                    onChange={handleChange}
                                />
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <a onClick={validatePassword}>
                                                    <strong>OK</strong>
                                                </a>
                                            </td>
                                            <td>
                                                <a onClick={hidePasswordDialog}>
                                                    <strong>Cancel</strong>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Popup>}
                    {/* <div className="navbar-item">
                        <div className="buttons">
                        <a className="button">
                            <strong>Sign up</strong>
                        </a>
                        <a className="button is-light">
                            Log in
                        </a>
                        </div>
                    </div> */}
                </div>
            </div>
            </nav>
        </>
    )
}

export default NavBar
