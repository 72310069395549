import React, { useRef, useEffect, useState, useContext } from 'react'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { APIRequestContext } from '../wrappers/APIRequestContext'
import { FilterContext } from '../wrappers/FilterContext'
import withConfig from '../wrappers/withConfig'
import toast from './Toast'
import Popup from 'reactjs-popup'
import MapSettings from './MapSettings'
import MapZoom from './MapZoom'
import WellDetails from './WellDetails'

mapboxgl.accessToken = 'pk.eyJ1IjoiZ3dwY21hcCIsImEiOiJjazZzZDNhc3MwMjJqM2RyeGQ3OGRxenplIn0.E2THeQU9KXzXt_qy8e_jQA';

const mapStyles = [
    'mapbox://styles/mapbox/streets-v11',
    'mapbox://styles/gwpcmap/ckbl5thx104re1mpesb4tb63s',
    'mapbox://styles/mapbox/satellite-streets-v11'
]

const MapPage = ({ config }) => {
    const mapContainerRef = useRef(null);
    const [map, setMap] = useState(null);
    const [lng, setLng] = useState(-98.5795);
    const [lat, setLat] = useState(39.8283);
    const [zoom, setZoom] = useState(3.5);
    const [style, setStyle] = useState(mapStyles[0]);
    const [showBoundaries, setShowBoundaries] = useState(true)
    const [showCenter, setShowCenter] = useState(true)
    const [ well, setWell ] = useState({})
    const [ filter ] = useContext(FilterContext)
    const [ authenticatedFetch, env, ] = useContext(APIRequestContext)
    const { API_URL } = config
    const sourceId = 'wells';
    const heatmapLayerId = 'wells-heatmap';
    const symbolLayerId = 'wells-symbol';
    const maxZoom = 13;

    const eq = (a, b) => ['==', a, b];
    const get = (a) => ['get', a];
    const all = (...args) => ['all'].concat(...args);
    const any = (...args) => ['any'].concat(...args);
    
    const createExpression = (type) => {
        if (filter.IsVisible) {
            return all([eq(get('TYPE'), type), eq(get('VISIBLE'), 'TRUE')]);
        } else {
            return eq(get('TYPE'), type);
        }
    };

    useEffect(() => {
        if (map === undefined || map === null)
            return
        
        // update the filter based on changes
        let expressions = []
        if (filter.Oil) expressions.push(createExpression('OIL'))
        if (filter.Gas) expressions.push(createExpression('GAS'))
        if (filter.OG) expressions.push(createExpression('OG'))
        if (filter.SWD) expressions.push(createExpression('SWD'))
        if (filter.EOR) expressions.push(createExpression('EOR'))
        if (filter.OTHER) expressions.push(createExpression('OTHER'))
        const expression = any(expressions)

        map.setFilter(heatmapLayerId, expression)
        map.setFilter(symbolLayerId, expression)

    }, [map, filter]);

    useEffect(() => {
        if (map === undefined || map === null)
            return
        
        // update the boundaries visibility
        map.setLayoutProperty(
            'state-boundary-layer',
            'visibility',
            showBoundaries? 'visible' : 'none'
        )

        map.setLayoutProperty(
            'county-boundary-layer',
            'visibility',
            showBoundaries? 'visible' : 'none'
        )

    }, [map, showBoundaries]);

    const addHeatmapLayer = (map) => {
        map.addLayer(
            {
            'id': heatmapLayerId,
            'type': 'heatmap',
            'source': sourceId,
            'source-layer': 'wells',
            'maxzoom': maxZoom,
            'paint': {
            // Increase the heatmap color weight weight by zoom level
            // heatmap-intensity is a multiplier on top of heatmap-weight
            'heatmap-intensity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, 3,
            maxZoom, 1
            ],
            // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
            // Begin color ramp at 0-stop with a 0-transparancy color
            // to create a blur-like effect.
            'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0, 'rgba(255, 255, 255, 0.01)',
            0.25, 'rgb(4, 179, 183)',
            0.75, 'rgb(252, 167, 55)',
            1, 'rgb(255, 78, 70)'  
            ],
            // Adjust the heatmap radius by zoom level
            'heatmap-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, 3,
            10, 8,
            maxZoom, 25
            ],
            // Transition from heatmap to circle layer by zoom level
            'heatmap-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, 1,
            maxZoom, 0.75
            ]
            }
            }
        );
    }

    const addSymbolLayer = (map) => {      
        const images = [
            {
                url:'/images/types/oil.png',
                id:'OIL'
            },
            {
                url:'/images/types/gas.png',
                id:'GAS'
            },
            {
                url:'/images/types/oilgas.png',
                id:'OG'
            },
            {
                url:'/images/types/swd.png',
                id:'SWD'
            },
            {
                url:'/images/types/eor.png',
                id:'EOR'
            },
            {
                url:'/images/types/other.png',
                id:'OTHER'
            }
        ]
        Promise.all(
            images.map(img => new Promise((resolve, reject) => {
                map.loadImage(img.url, function (error, res) {
                    if (error) throw error;
                    map.addImage(img.id, res)
                    resolve();
                })
            }))
        ).then(() => {
            map.addLayer(
                {
                    'id': symbolLayerId,
                    'type': 'symbol',
                    'source': sourceId,
                    'source-layer': 'wells',
                    'minzoom': maxZoom,
                    'layout': {
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true,
                        'icon-size': 0.75,
                        'icon-image': '{TYPE}',    
                    }
                }
            )

            // Create a popup, but don't add it to the map yet.
            const popup = new mapboxgl.Popup({
                closeButton: true,
                closeOnClick: false
            });

            var timer = null;

            map.on('click', symbolLayerId, (e) => {
                // Copy coordinates array.
                const coordinates = e.features[0].geometry.coordinates.slice();
                    
                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                
                // Populate the popup and set its coordinates
                // based on the feature found.
                popup.setLngLat(coordinates)
                .setHTML(`<div class="well-popup"><h2>${e.features[0].properties.NAME}</h2><p><strong>API: </strong>${e.features[0].properties.API}</p><a class="button well-details-button"><strong>Well Details</strong></a></div>`)
                .addTo(map);

                const wellDetails = document.getElementsByClassName("well-details-button")[0];
                wellDetails.API = e.features[0].properties.API;
                wellDetails.ID = e.features[0].properties.ID;
                wellDetails.addEventListener("click", (event) => {
                    event.preventDefault();
                    doSearch(event.currentTarget);
                });
            })
         
            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on('mouseenter', symbolLayerId, (e) => {
                map.getCanvas().style.cursor = 'pointer';

                timer = setTimeout(() => {
                    // Copy coordinates array.
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    
                    // Ensure that if the map is zoomed out such that multiple
                    // copies of the feature are visible, the popup appears
                    // over the copy being pointed to.
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }
                    
                    // Populate the popup and set its coordinates
                    // based on the feature found.
                    popup.setLngLat(coordinates)
                    .setHTML(`<div class="well-popup"><h2>${e.features[0].properties.NAME}</h2><p><strong>API: </strong>${e.features[0].properties.API}</p><a class="button well-details-button"><strong>Well Details</strong></a></div>`)
                    .addTo(map);

                    const wellDetails = document.getElementsByClassName("well-details-button")[0];
                    wellDetails.API = e.features[0].properties.API;
                    wellDetails.ID = e.features[0].properties.ID;
                    wellDetails.addEventListener("click", (event) => {
                        event.preventDefault();
                        doSearch(event.currentTarget);
                    });
                }, 3000);              
            })
         
            // Change it back to a pointer when it leaves.
            map.on('mouseleave', symbolLayerId, () => {
                map.getCanvas().style.cursor = '';
                if (timer != null) {
                    clearTimeout(timer);
                    timer = null;
                }
            })
        })
    }

    const addBoundaryLayer = (map) => {

       map.addSource('us-state-boundaries-5b6v9p', {
            type: 'vector',
            url: 'mapbox://gwpcmap.2oze13cf'
        });

        map.addLayer(
            {
                'id': 'state-boundary-layer',
                'type': 'line',
                'source': 'us-state-boundaries-5b6v9p',
                'source-layer': 'us-state-boundaries-5b6v9p',
                'maxzoom': 7,
                'paint': {
                    'line-width': 2,
                    'line-color': 'rgb(56, 126, 245)'   
                }
            }
        );

        map.addSource('us-county-boundaries-08dqgf', {
            type: 'vector',
            url: 'mapbox://gwpcmap.0jv6tkqt'
        });

        map.addLayer(
            {
                'id': 'county-boundary-layer',
                'type': 'line',
                'source': 'us-county-boundaries-08dqgf',
                'source-layer': 'us-county-boundaries-08dqgf',
                'minzoom': 7,
                'paint': {
                    'line-width': 2,
                    'line-color': 'rgb(56, 126, 245)'   
                }
            }
        );
    }

    const setMapStyle = (index) => { 
        setStyle(mapStyles[index])
    }

    const loadItem = (name, value, setValue) => { 
        let val = window.sessionStorage.getItem(name)
        if (val != null) {
            setValue(val)
            return val
        }
        return value;
    }

    const zoomToBounds = (b) => { 
        map.fitBounds(b);
        let _lat = map.getCenter().lat.toFixed(4);
        let _lng = map.getCenter().lng.toFixed(4);
        let _zoom = map.getZoom().toFixed(2);
        window.sessionStorage.setItem('zoom', _zoom);
        window.sessionStorage.setItem('latitude', _lat);
        window.sessionStorage.setItem('longitude', _lng);
    }

    // Initialize map when component mounts
    useEffect(() => {

        // read locals from session storage
        let _lat = loadItem('latitude', lat, setLat)
        let _lng = loadItem('longitude', lng, setLng)
        let _zoom = loadItem('zoom', zoom, setZoom)

        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: style,
            center: [_lng, _lat],
            zoom: _zoom,
            attributionControl: false
        });

         // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl({ showCompass: true, showZoom: true, visualizePitch: true }), 'top-right');

        map.on('move', () => {
            let lat = map.getCenter().lat.toFixed(4)
            let lng = map.getCenter().lng.toFixed(4)
            let zoom = map.getZoom().toFixed(2)
            
            setLng(lng)
            setLat(lat)
            setZoom(zoom)

            window.sessionStorage.setItem('zoom', zoom)
            window.sessionStorage.setItem('latitude', lat)
            window.sessionStorage.setItem('longitude', lng)
        });

        let mapboxUrl = env === 'development'? 'mapbox://gwpcmap.dev-wells-large-point-dataset' : 'mapbox://gwpcmap.wells-large-point-dataset'

        map.on('load', () => {
            map.addSource(sourceId, {
                type: 'vector',
                url: mapboxUrl
            });

            addBoundaryLayer(map);

            addHeatmapLayer(map);

            addSymbolLayer(map);

            let markers = window.sessionStorage.getItem('markers')
            if (markers != null) {
                // fit bounds
                let m = JSON.parse(markers);

                m.map((item) => (
                    new mapboxgl.Marker().setLngLat(item).addTo(map)
                ))
                
                window.sessionStorage.removeItem('markers');
            }

            setMap(map);

            let bounds = window.sessionStorage.getItem('bounds')
            if (bounds != null) {
                // fit bounds
                let b = JSON.parse(bounds);
                map.fitBounds(b);
                let _lat = map.getCenter().lat.toFixed(4);
                let _lng = map.getCenter().lng.toFixed(4);
                let _zoom = map.getZoom().toFixed(2);
                window.sessionStorage.setItem('zoom', _zoom);
                window.sessionStorage.setItem('latitude', _lat);
                window.sessionStorage.setItem('longitude', _lng);
                window.sessionStorage.removeItem('bounds');
            }
        });

        // Clean up on unmount
        return (() => { 
                map.remove()
            }
        )       
    }, [env, style]);

    const doSearch = (search) => {
        authenticatedFetch(search.ID !== undefined? `${API_URL}?method=getWellByID&id=${search.ID}` : `${API_URL}?method=getWellByAPI&api=${search.API}`, {
            crossDomain: true })
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                if (response.ADATA !== undefined && response.ADATA.length > 0)
                    setWell(response.ADATA[0])
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(
                
            )
    }

    const onCloseDetails = () => {
        setWell({})
    }
    
    return (
        <div>
            {showCenter &&
            <div className="sidebar">
                Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
            </div>}
            <Popup className="map-params" trigger={<img className="map-button" src="/images/layers.png" />} position="right top">
                <MapSettings 
                    showBoundaries={showBoundaries} 
                    setShowBoundaries={setShowBoundaries}
                    showCenter={showCenter} 
                    setShowCenter={setShowCenter}
                    setMapStyle={setMapStyle} 
                />
            </Popup>
            <Popup className="map-params" trigger={<img className="map-button-zoom" src="/images/search.png" />} position="right top">
                <MapZoom 
                    zoomToBounds={zoomToBounds}
                />
            </Popup>
            {well.API !== undefined &&
                <Popup className="map-well-details" open={true} closeOnDocumentClick onClose={onCloseDetails}>
                    <WellDetails result={well} onClose={onCloseDetails} showZoom={false} />
                </Popup>               
            }
            <div ref={mapContainerRef} className="map-container" />
        </div>
    )
}

export default withConfig(MapPage)