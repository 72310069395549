import React, { useState, useEffect } from 'react'
import DetailsLink from './DetailsLink'
import SortByPanel from './SortByPanel'
import { useNavigate } from "react-router-dom";
import urls from '../../utils/constants/urls';

const WellList = ({ result, onSelected }) => {
    const [ wells, setWells ] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (result && result.ADATA instanceof Array)
            sortByChanged('API', false)
    }, [result]);

    const images = [
        {
            url:'/images/types/oil.png',
            id:'OIL'
        },
        {
            url:'/images/types/gas.png',
            id:'GAS'
        },
        {
            url:'/images/types/oilgas.png',
            id:'OG'
        },
        {
            url:'/images/types/swd.png',
            id:'SWD'
        },
        {
            url:'/images/types/eor.png',
            id:'EOR'
        },
        {
            url:'/images/types/other.png',
            id:'OTHER'
        }
    ]

    // This function allows us to have commas, line breaks, and double quotes in our value without breaking CSV format.
    const prepareValueForCSV = (val) => {
        if (val.startsWith("http")) {
            val = `=HYPERLINK("${val}", "${val}")`
        };

        val = '' + val;
        // Escape quotes to avoid ending the value prematurely.
        val = val.replace(/"/g, '""')
        return `"${val}"`
    }

    const wellsToCSV = () => {
        var columnNames = ["State", "Company", "API", "Name", "Type", "Status", "County", "Field", "Town", 
                    "Longitude", "Latitude", "State Email", "Phone", "Well Link", "FracFocus Link"];
        var rows = [];
        for (var i = 0, len = wells.length; i < len; i++) {
            // Each obj represents a row in the table
            var obj = wells[i];
            // row will collect data from obj
            var row = [obj.STATE, obj.COMPANY, `="${obj.API}"`, obj.NAME, obj.TYPE, obj.STATUS, obj.COUNTY, obj.FIELD, obj.TOWN,
                       obj.GEOMETRY[0], obj.GEOMETRY[1], obj.STATEEMAIL, obj.PHONE, obj.WELL_LINK, obj.FRACFOCUSURL];

            for (var j = 0, len2 = row.length; j < len2; j++) {
                row[j] = prepareValueForCSV(row[j] == null? "" : row[j].toString().trim())
            }
            // Push each row to the main collection as csv string
            rows.push(row.join(','));
        }
        // Put the columnNames at the beginning of all the rows
        rows.unshift(columnNames.join(','));
        // Return the csv string
        return rows.join('\n');
    }

    const currentDate = () => {
        var d = new Date();
        var mm = d.getMonth() + 1;
        var dd = d.getDate();
        var hh = d.getHours();
        var m = d.getMinutes();
        return `${d.getFullYear().toString()}${(mm > 9 ? '' : '0') + mm.toString()}${(dd > 9 ? '' : '0') + dd.toString()}_${(hh > 9 ? '' : '0') + hh.toString()}${(m > 9 ? '' : '0') + m.toString()}`
    }

    const downloadWells = () => {
        let csv = wellsToCSV();

        let csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
        let csvFileName = `wells_${currentDate()}.csv`
        //IE11 & Edge
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(csvData, csvFileName);
        } else {
            //In FF link must be added to DOM to be clicked
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(csvData);
            link.setAttribute('download', csvFileName);
            document.body.appendChild(link);    
            link.click();
            document.body.removeChild(link);    
        }
    }

    const showOnMap = () => {
        // calculating the bbox of the wells
        if (wells.length > 1) {
            // set bounds in [sw, ne]
            let sw = [wells[0].GEOMETRY[0], wells[0].GEOMETRY[1]]
            let ne = [wells[0].GEOMETRY[0], wells[0].GEOMETRY[1]]
            for (let i = 1; i < wells.length; i++) {
                let g = wells[i].GEOMETRY
                if (g[0] < sw[0])
                    sw[0] = g[0]
                if (g[0] > ne[0])
                    ne[0] = g[0]
                if (g[1] < sw[1])
                    sw[1] = g[1]
                if (g[1] > ne[1])
                    ne[1] = g[1]
            }
            window.sessionStorage.setItem('bounds', JSON.stringify([sw, ne]))
            window.sessionStorage.setItem('latitude', 39.8283)
            window.sessionStorage.setItem('longitude', -98.5795)
            window.sessionStorage.setItem('zoom', 3.5)
            window.sessionStorage.setItem('markers', JSON.stringify(wells.map(a => a.GEOMETRY)))

            navigate(urls.map)
        }
        else if (wells.length == 1) {
            // navigate to a single well
            let lat = wells[0].GEOMETRY[1].toFixed(4)
            let lng = wells[0].GEOMETRY[0].toFixed(4)

            window.sessionStorage.setItem('zoom', 17)
            window.sessionStorage.setItem('latitude', lat)
            window.sessionStorage.setItem('longitude', lng)

            navigate(urls.map)
        }
    }

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const wellDetails = (item) => {
        if (onSelected === undefined) {
            openInNewTab(`${urls.apiSearch}/${item.API}`)            
        }
        else
            onSelected(item)
    }

    const sortByChanged = (item, sortUp) => {
        if (item === 'API') {
            if (sortUp)
                setWells(result.ADATA.slice().sort((a, b) => a.API < b.API ? 1 : -1))
            else
                setWells(result.ADATA.slice().sort((a, b) => a.API > b.API ? 1 : -1))
        }
        else if (item === 'Name') {
            if (sortUp)
                setWells(result.ADATA.slice().sort((a, b) => a.NAME < b.NAME ? 1 : -1))
            else
                setWells(result.ADATA.slice().sort((a, b) => a.NAME > b.NAME ? 1 : -1))
        }
    }

    if (result === 'loading') {
        return (<h2>Loading ...</h2>)
        // return <TailSpin className="spinner"
        //     height='30'
        //     width='30'
        //     color='grey'
        //     ariaLabel='loading'
        // />
    }

    if (!result || result.SUCCESS === undefined) {
        return null
    }

    if (result.SUCCESS !== true) {
        return (
            <h2>{result.MESSAGE}</h2>
        )
    }

    return (
        <>
            <table>
                <tbody>
                    <tr>
                        <td colSpan={3} className="well-result">
                            <span>Number of Wells ({wells.length}): </span> <a download="wells.csv" onClick={downloadWells}>Download</a> <a onClick={showOnMap}>Show on Map {'>'}</a>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>
                            <SortByPanel names={['API', 'Name']} selected='API' order={false} sortByChanged={sortByChanged} />    
                        </td>
                    </tr>
                    {wells.map((item, i) => (
                        <tr key={i}>
                            <td><img src={images.find(x => x.id === item.TYPE).url} /></td>
                            <td className="search-data"><span>{item.NAME}</span><span>{item.COUNTY}, {item.STATE} - {item.API}</span></td>
                            <td className="nav-next"><DetailsLink value={item} handleClick={wellDetails} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default WellList