import { getValue } from '@testing-library/user-event/dist/utils'
import React, { createContext, useState } from 'react'

const SavedWellsContext = createContext(null)

const SavedWellsContextProvider = ({ children }) => {

    const getWells = () => {
        let sws = window.localStorage.getItem('savedwells')
        if (sws != null) {
            let sw = JSON.parse(sws)
            if (sw != null && sw.length > 0) {
                return ({
                    SUCCESS: true,
                    ADATA: sw
                })
            }
        }
    }

    const [wells, setWells] = useState(getWells())

    const addWell = (val) => { 
        let w = wells != null? wells.ADATA : []
        w.push(val)
        window.localStorage.setItem('savedwells', JSON.stringify(w))
        setWells({  
            SUCCESS: true,
            ADATA: w
        })
    }

    const removeWell = (val) => { 
        let w;
        if (wells.ADATA.findIndex(e => e._id == val._id) !== -1) {
            w = wells.ADATA.filter(data => data._id != val._id)
        }
        else if (wells.ADATA.findIndex(e => e.API == val.API && e.GEOMETRY[0] == val.GEOMETRY[0] && e.GEOMETRY[1] == val.GEOMETRY[1]) !== -1) {
            w = wells.ADATA.filter(data => data.API != val.API && data.GEOMETRY[0] == val.GEOMETRY[0] && data.GEOMETRY[1] == val.GEOMETRY[1])
        }
        else {
            w = wells.ADATA.filter(data => data.API != val.API)
        }
        window.localStorage.setItem('savedwells', JSON.stringify(w))
        setWells({
            SUCCESS: true,
            ADATA: w
        })
    }

    return (
        <SavedWellsContext.Provider
            value={[wells, addWell, removeWell]}
        >
            {children}
        </SavedWellsContext.Provider>
    )
}

export { SavedWellsContext }
export default SavedWellsContextProvider
