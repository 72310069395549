const urls = {
    home: '/',
    apiSearch: '/wellapi-search',
    idSearch: '/wellid-search',
    wellNameSearch: '/wellname-search',
    map: '/map',
    browseWells: '/browse-wells',
    savedWells: '/saved-wells'
}

export default urls