import React, { useState } from 'react'

const Switch = ( {id, check, onChanged} ) => {
    const [checked, setChecked] = useState(check);

    const valueChanged = (e) => {
        onChanged(!checked)
        setChecked(!checked)    
    }

    return (
        <>
            <label className="toggle-switch">
                <input type="checkbox" id={id} className="toggle-switch-input" checked={checked ? 'checked' : ''}
                onChange={valueChanged} />
                <label htmlFor={id} className="toggle-switch-label"></label>
            </label>           
        </>
    )
}

export default Switch