import React from 'react'
import Switch from './Switch'

const MapSettings = ({ showBoundaries, setShowBoundaries, showCenter, setShowCenter, setMapStyle }) => {
    
    return (
        <>
            <h2>
                Map Styles
            </h2>
            <a className="button" onClick={() => setMapStyle(0)}>
                <strong>Streets</strong>
            </a>
            <a className="button" onClick={() => setMapStyle(1)}>
                <strong>Terrain</strong>
            </a>
            <a className="button" onClick={() => setMapStyle(2)}>
                <strong>Satellite</strong>
            </a>
            <hr />
            <h2>
                Map Layers
            </h2>
            <table>
                <tbody>
                    <tr>
                        <td>
                        Show Map Boundaries
                        </td>
                        <td className="map-params-switch">
                        <Switch id="map-showboundaries" check={showBoundaries} onChanged={setShowBoundaries} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                        Show Center and Zoom
                        </td>
                        <td className="map-params-switch">
                        <Switch id="map-showcenter" check={showCenter} onChanged={setShowCenter} />
                        </td>
                    </tr>
                </tbody>       
            </table>
        </>
    )
}

export default MapSettings