import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

const NavBarItem = ({ name, route, icon }) => {
    return (
        <Routes>
          <Route path={route} element={
          <a className="navbar-item navbar-item-sel" href={route}>
            <img src={icon} />
            {name}
        </a>} />
          <Route path="*" element={<a className="navbar-item" href={route}>
            <img src={icon} />
            {name}
        </a>} />
        </Routes>        
    )
}

export default NavBarItem
